import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useAddonEncounters = (forInsurance: boolean) => {
   const { data, ...rest } = useQuery({
      queryKey: queryKeys.addonEncounters(forInsurance),
      queryFn: async () => {
         const response =
            await webApiClient.scheduling.getAvailableAddonEncounters({
               query: {
                  forInsurance,
               },
            });
         return response.body;
      },
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
   });

   return {
      ...rest,
      therapyCategoryId: data?.therapyCategoryId ?? null,
      prescriberCategoryId: data?.prescriberCategoryId ?? null,
      therapyAddons: data?.therapyAddons ?? [],
      prescriberAddons: data?.prescriberAddons ?? [],
   };
};
