import { FormsortFormTypes } from '@innerwell/dtos';

export type FormsortMedicalIntakeForm = {
   id: number;
   name: string;
   enum: FormsortFormTypes;
   link: string;
};

export const formsortMIForms: FormsortMedicalIntakeForm[] = [
   {
      id: 1,
      name: 'Profile',
      enum: FormsortFormTypes.MedicalIntakeProfile,
      link: '/medical-intake/profile',
   },
   {
      id: 2,
      name: 'Physical Health Profile',
      enum: FormsortFormTypes.MedicalIntakeMedicalHistory,
      link: '/medical-intake/medical-history',
   },
   {
      id: 3,
      name: 'Mental Health Profile',
      enum: FormsortFormTypes.MedicalIntakePsychiatricHistory,
      link: '/medical-intake/psychiatric-history',
   },
   {
      id: 4,
      name: 'Lifestyle',
      enum: FormsortFormTypes.MedicalIntakeMentalHealth,
      link: '/medical-intake/mental-health-and-wellness',
   },
   {
      id: 5,
      name: 'Psychedelic Experience',
      enum: FormsortFormTypes.MedicalIntakePsychedelicExperiences,
      link: '/medical-intake/psychedelic-experiences',
   },
];
