import { Flex, type FlexProps } from '@chakra-ui/react';

export const ButtonGroup = (flexProps: FlexProps) => {
   return (
      <Flex
         flexDir={{ base: 'column', lg: 'row' }}
         alignItems={{ base: 'flex-start', lg: 'center' }}
         gap={{ base: 3, lg: 6 }}
         mt={{ base: 5, lg: 8 }}
         {...flexProps}
      />
   );
};
