import { getStaggerVariants } from '@/utils/animation-utils';
import {
   AccordionButton,
   AccordionItem,
   type AccordionItemProps,
   AccordionPanel,
   Box,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

type Props = {
   expandedComponent?: React.ReactNode;
   initialComponent?: React.ReactNode;
} & AccordionItemProps;

const staggerVariants = getStaggerVariants();

export const StepAccordionWrapper = ({
   initialComponent: InitialComponent,
   expandedComponent: ExpandedComponent,
   ...props
}: Props) => {
   return (
      <AccordionItem border={0} mb={4} mt={-2} mx={-2} {...props}>
         {({ isExpanded }) => (
            <motion.div variants={staggerVariants.child}>
               <AccordionButton display="none" />

               {!isExpanded && InitialComponent ? (
                  <Box p={2}>{InitialComponent}</Box>
               ) : null}

               <AccordionPanel p={2} _empty={{ display: 'none' }}>
                  {isExpanded ? ExpandedComponent : null}
               </AccordionPanel>
            </motion.div>
         )}
      </AccordionItem>
   );
};
