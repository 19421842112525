import CreateStepContext from '@/contexts/step-context';

export enum MedicalIntakeStepsEnum {
   Landing,
   Formsort,
}

const {
   StepProvider: MedicalIntakeStepProvider,
   useStep: useMedicalIntakeStep,
} = CreateStepContext<MedicalIntakeStepsEnum>({
   initialStep: MedicalIntakeStepsEnum.Landing,
});

export { MedicalIntakeStepProvider, useMedicalIntakeStep };
