import {
   NextLinkButton,
   type NextLinkButtonProps,
} from '@/components/NextLinkButton/NextLinkButton';

export function ButtonLink<THref extends string>({
   variant,
   ...rest
}: NextLinkButtonProps<THref>) {
   return (
      <NextLinkButton
         size={{ base: 'xs', lg: 'sm' }}
         minH={{
            base: variant === 'link' ? 'auto!' : '48px',
            lg: variant === 'link' ? 'auto!' : '48px',
         }}
         h={{ base: 'auto!', lg: 'auto' }}
         position="relative"
         zIndex={1}
         variant={variant}
         {...rest}
      />
   );
}
