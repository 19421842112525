import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export default function useInsurances(
   {
      enabled,
      state,
   }: {
      enabled?: boolean;
      state: string;
   } = {
      state: '',
   },
) {
   const { data, ...rest } = useQuery({
      queryKey: queryKeys.insuranceForState(state),
      queryFn: async () => {
         const insurances = await webApiClient.insurance.getPayers({
            query: { state },
         });
         return insurances.body;
      },
      enabled: enabled ?? true,
   });

   return {
      ...rest,
      insurances: data || [],
   };
}
