import { Box, Flex, type FlexProps, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';

import { Icon } from '@/components/Icon';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import animation from '@/lottie/lottie-doors-animation.json';

export const PurchaseFollowOnCard = ({ ...props }: FlexProps) => {
   return (
      <Flex
         direction="column"
         alignItems="center"
         color="text.primary"
         gap={5}
         maxW="35.75rem"
         mx="auto"
         textAlign="center"
         {...props}
      >
         <Box
            as={Lottie}
            animationData={animation}
            loop
            w={{ base: '3.9rem', lg: '5.7rem' }}
         />

         <Text size="leadText" fontWeight={600}>
            What’s next after your plan?
         </Text>

         <Text>
            {`The benefits of ketamine can be long-lasting, and ongoing 
               treatment can help to maintain them. If you'd like to continue 
               treatment beyond your current plan, choose one of our follow-on 
               options.`}
         </Text>

         <NextLinkButton
            href="/purchase/follow-on"
            size="sm"
            icon={<Icon name="chevron-right" w={5} h={5} strokeWidth={2} />}
            maxW="17rem"
         >
            See Plans
         </NextLinkButton>
      </Flex>
   );
};
