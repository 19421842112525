import {
   Accordion,
   AccordionButton,
   AccordionIcon,
   AccordionItem,
   Box,
   CircularProgress,
   CircularProgressLabel,
   Flex,
   type FlexProps,
   Text,
} from '@chakra-ui/react';
import { NextImage } from '@innerwell/chakra/images';
import { IntakeProgramPhases, TherapyPlan } from '@innerwell/dtos';
import { getFormsortIntakeProgress } from '@innerwell/utils';
import { type Route } from 'next';

import usePatientLastCarePlanChoice from '@/hooks/react-query/usePatientLastCarePlanChoice';

import AccordionItemProgress, {
   MedicalIntakeProgressStatus,
} from '@/components/ListingItems/AccordionItemProgress';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { webApiClient } from '@/api-client/apiClient';
import { usePatientProgram } from '@/contexts/patient-program-context';
import { useSession } from '@/contexts/session-context';
import {
   type FormsortMedicalIntakeForm,
   formsortMIForms,
} from '@/utils/formsortForms';
import { useEffect, useMemo, useState } from 'react';

const PROGRESS_START_TEXT = `Please tell us a bit more about yourself including your
                     Physical and Mental Health history. This should take less
                     than 20 minutes. Need more time? No worries! We'll keep
                     track of your progress so you can resume when ready.`;

const PROGRESS_ONGOING_TEXT = (name: string) =>
   `Nice progress, ${name}! Continue onboarding below.`;

const PROGRESS_NEAR_END_TEXT = (name: string) =>
   `You're almost done, ${name}! Keep going and we'll see you in your medical consultation.`;

const IntakeStep: React.FC<FlexProps> = (props) => {
   const {
      programPhase: { phase },
   } = usePatientProgram();
   const session = useSession();

   const { chosenCarePlan, isLoading: isPatientChosenCarePlanLoading } =
      usePatientLastCarePlanChoice();

   const [accordionText, setAccordionText] = useState('View my progress');
   const intakeInProgress =
      phase === IntakeProgramPhases.MedicalIntakeInProgress ||
      phase === IntakeProgramPhases.MedicalIntakeStarted;

   const [percentageCompleted, setPercentageCompleted] = useState<
      number | null
   >(null);

   const isNonKetamineIntake =
      !!chosenCarePlan &&
      [
         TherapyPlan.GeneralPsychiatryPlan,
         TherapyPlan.GeneralTherapyPlan,
         TherapyPlan.EMDR,
      ].includes(chosenCarePlan);

   const [intakeForms, setIntakeForms] = useState<
      (FormsortMedicalIntakeForm & {
         status: MedicalIntakeProgressStatus;
      })[]
   >([]);

   useEffect(() => {
      if (isPatientChosenCarePlanLoading) {
         return;
      }

      setIntakeForms(
         isNonKetamineIntake
            ? []
            : formsortMIForms.map((form) => ({
                 ...form,
                 status: MedicalIntakeProgressStatus.NOT_STARTED,
              })),
      );
   }, [isNonKetamineIntake, isPatientChosenCarePlanLoading]);

   const ctaLink = useMemo(() => {
      if (isPatientChosenCarePlanLoading) {
         return;
      }

      // Not handling existing forms, as per old intake flow that we used
      // in General Psych and Therapy plans
      if (isNonKetamineIntake) {
         return '/intake' as const;
      }

      let currentFormInProgress = intakeForms
         .filter((f) => f.status === MedicalIntakeProgressStatus.IN_PROGRESS)
         .at(0);

      if (!currentFormInProgress) {
         currentFormInProgress = intakeForms
            .filter((f) => f.status === MedicalIntakeProgressStatus.NOT_STARTED)
            .at(0);
      }

      return (
         (currentFormInProgress?.link as Route) || ('/medical-intake' as const)
      );
   }, [intakeForms, isPatientChosenCarePlanLoading, isNonKetamineIntake]);

   useEffect(() => {
      if (
         session.data &&
         intakeInProgress &&
         !isNonKetamineIntake &&
         !isPatientChosenCarePlanLoading
      ) {
         const getAnswers = async () => {
            const allFormsortForms = await webApiClient.formsort.list();

            const progress = getFormsortIntakeProgress(allFormsortForms.body);

            if (progress) {
               setPercentageCompleted(parseInt(progress.percentageCompleted));

               const startedForms = progress.forms;
               setIntakeForms((prev) => {
                  return prev.map((form) => {
                     const startedForm = startedForms.find(
                        (f) => f.formsort_form_type === form.enum,
                     );

                     if (!startedForm) {
                        return form;
                     }

                     return {
                        ...form,
                        status: startedForm.finalized
                           ? MedicalIntakeProgressStatus.COMPLETED
                           : MedicalIntakeProgressStatus.IN_PROGRESS,
                     };
                  });
               });
            }
         };

         getAnswers().catch((error) => {
            console.error(error);
         });
      }
   }, [
      intakeInProgress,
      isNonKetamineIntake,
      isPatientChosenCarePlanLoading,
      session.data,
   ]);

   return (
      <Flex
         direction="column"
         bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
         borderRadius={12}
         overflow="hidden"
         {...props}
      >
         <Box
            pb={{ base: 8, lg: 9 }}
            pt={{ base: 4, lg: 8 }}
            px={{ base: 6, lg: 10 }}
            pos="relative"
         >
            {!intakeInProgress && (
               <NextImage
                  src="/images/onboarding-card-image.png"
                  display={{ base: 'none', lg: 'block' }}
                  w="180px"
                  h="180px"
                  imgWidth={409}
                  imgHeight={367}
                  pos="absolute"
                  objectFit="cover"
                  objectPosition="60% 100%"
                  right={0}
                  bottom={0}
                  zIndex={0}
                  alt="Onboarding card image"
               />
            )}
            <Box pos="relative" zIndex={2}>
               <Flex alignItems="center">
                  <Text size="leadText" color="black" mb={3}>
                     Onboarding
                  </Text>
               </Flex>
            </Box>
            <Flex alignItems="flex-start" pos="relative" zIndex={2}>
               {intakeInProgress && percentageCompleted !== null ? (
                  <Box pr={{ base: 4, lg: 6 }}>
                     <CircularProgress
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore -- Chakra definition of size is not properly implemented,
                        // it does support responsive sizes.
                        size={{ base: '95px', lg: '150px' }}
                        value={percentageCompleted}
                        color="background.fourth"
                     >
                        <CircularProgressLabel
                           display="flex"
                           flexDirection="column"
                           color="black"
                           justifyContent="center"
                           alignItems="center"
                           mt={1}
                        >
                           <Text
                              size="leadTitle"
                              color="text.primary"
                              lineHeight={{ base: 0.8, lg: 1.4 }}
                           >
                              {percentageCompleted}%
                           </Text>
                           <Text size="paragraphSmall" color="text.primary">
                              done
                           </Text>
                        </CircularProgressLabel>
                     </CircularProgress>
                  </Box>
               ) : null}
               <Flex direction="column" pr={3} alignItems="flex-start">
                  {isNonKetamineIntake ? (
                     <Text
                        size="body"
                        color="text.secondary"
                        w="100%"
                        maxW="420px"
                     >
                        {PROGRESS_START_TEXT}
                     </Text>
                  ) : (
                     <Text
                        size="body"
                        color="text.secondary"
                        w="100%"
                        maxW={{ lg: '420px' }}
                     >
                        {intakeInProgress && percentageCompleted !== null ? (
                           <>
                              {percentageCompleted < 50
                                 ? percentageCompleted === 0
                                    ? PROGRESS_START_TEXT
                                    : PROGRESS_ONGOING_TEXT(
                                         session.data?.given_name ?? '',
                                      )
                                 : PROGRESS_NEAR_END_TEXT(
                                      session.data?.given_name ?? '',
                                   )}
                           </>
                        ) : (
                           PROGRESS_START_TEXT
                        )}
                     </Text>
                  )}
                  <NextLinkButton
                     mt="24px"
                     size="sm"
                     disabled={!ctaLink}
                     href={ctaLink ?? '/'}
                  >
                     {intakeInProgress ? 'Continue' : 'Get Started'}
                  </NextLinkButton>
               </Flex>
            </Flex>
         </Box>
         {/* Showing progress only for medical-intake */}
         {intakeInProgress &&
         !isNonKetamineIntake &&
         !isPatientChosenCarePlanLoading ? (
            <Accordion
               borderRadius={12}
               bg="white"
               allowToggle
               onChange={(index: number) => {
                  if (index === 0) {
                     setAccordionText('Hide my progress');
                  } else {
                     setAccordionText('View my progress');
                  }
               }}
            >
               <AccordionItem borderBottom="none" color="text.primary">
                  <AccordionButton p={0} h={68} pl={{ base: 6, lg: 10 }}>
                     <Box textDecoration="underline" textUnderlineOffset="4px">
                        {accordionText}
                     </Box>
                     <AccordionIcon />
                  </AccordionButton>
                  {intakeForms.map((form) => {
                     return (
                        <AccordionItemProgress
                           key={form.id}
                           status={form.status}
                           text={form.name}
                        />
                     );
                  })}
               </AccordionItem>
            </Accordion>
         ) : null}
      </Flex>
   );
};

export default IntakeStep;
