import {
   AccordionPanel,
   Box,
   CircularProgress,
   Flex,
   Text,
} from '@chakra-ui/react';

import { Icon } from '../Icon';

export enum MedicalIntakeProgressStatus {
   NOT_STARTED = 'NOT_STARTED',
   IN_PROGRESS = 'IN_PROGRESS',
   COMPLETED = 'COMPLETED',
}

interface IProps {
   status?: MedicalIntakeProgressStatus;
   text: string;
}

const AccordionItemProgress: React.FC<IProps> = ({
   status = MedicalIntakeProgressStatus.NOT_STARTED,
   text,
}) => {
   return (
      <AccordionPanel
         p={0}
         h={67}
         borderTop="1px"
         borderColor="line.primary"
         display="flex"
         justifyContent="space-between"
      >
         <Flex pl={{ base: 6, lg: 10 }} alignItems="center">
            {/* Completed */}
            {status === MedicalIntakeProgressStatus.COMPLETED && (
               <Box
                  opacity={0.5}
                  display="flex"
                  borderRadius={50}
                  bg="background.primary"
                  w="28px"
                  h="28px"
                  alignItems="center"
                  justifyContent="center"
               >
                  <Icon name="checkmark" boxSize={5} color="white" />
               </Box>
            )}

            {/* Progress */}
            {status === MedicalIntakeProgressStatus.IN_PROGRESS && (
               <Box
                  display="flex"
                  borderRadius={50}
                  alignItems="center"
                  justifyContent="center"
               >
                  <CircularProgress
                     value={40}
                     thickness="17px"
                     size="28px"
                     color="background.primary"
                  />
               </Box>
            )}

            {/* Not started */}
            {status === MedicalIntakeProgressStatus.NOT_STARTED && (
               <Box
                  display="flex"
                  borderRadius={50}
                  alignItems="center"
                  justifyContent="center"
               >
                  <CircularProgress
                     value={0}
                     thickness="17px"
                     size="28px"
                     color="background.primary"
                  />
               </Box>
            )}

            <Text
               pl={3}
               size="paragraph"
               opacity={
                  status === MedicalIntakeProgressStatus.COMPLETED ? 0.5 : 1
               }
            >
               {text}
            </Text>
         </Flex>
      </AccordionPanel>
   );
};

export default AccordionItemProgress;
