'use client';
import { Flex, Heading, Text, Box, Skeleton } from '@chakra-ui/react';
import { IntakeProgramPhases, WelkinPrograms } from '@innerwell/dtos';
import { useRouter, useSearchParams } from 'next/navigation';

import { ExtendedLayout } from '@/components/Home/ExtendedLayout';
import { IntakeLayout } from '@/components/Home/IntakeLayout';
import { InnerwellLogo } from '@/components/Images';
import { WelcomeModal } from '@/components/Modals/WelcomeModal';

import { useSession } from '@/contexts/session-context';
import { motion } from 'framer-motion';
import { getStaggerVariants } from '@/utils/animation-utils';
import useMyPatient from '@/hooks/react-query/useMyPatient';

const variants = getStaggerVariants({ staggerChildren: 0.1 });

export const HomepageClientPage = () => {
   const { data: session } = useSession();
   const { replace } = useRouter();
   const searchParams = useSearchParams();
   const showWelcomeModal = searchParams.get('show_welcome_modal') === '1';
   const { data: patient } = useMyPatient();

   const handleWelcomeModalClose = () => {
      replace('/');
   };

   return (
      <Flex
         maxW={{ lg: 'container.md' }}
         flexDir="column"
         mx="auto"
         pt={{ base: 'none', lg: '3.75rem' }}
      >
         {showWelcomeModal ? (
            <WelcomeModal onCloseComplete={handleWelcomeModalClose} />
         ) : null}

         <InnerwellLogo mb={9} mt={7} fill="white" display={{ lg: 'none' }} />

         {!!session && (
            <motion.div
               variants={variants.container}
               initial="hidden"
               animate="show"
            >
               <motion.div variants={variants.child}>
                  <Text>{`Hi, ${session?.given_name}`}</Text>
               </motion.div>
               <motion.div variants={variants.child}>
                  <Heading>Next Steps</Heading>
               </motion.div>
            </motion.div>
         )}

         <Box pt={5} pb={{ base: 12, lg: 8 }}>
            {patient?.currentProgram ? (
               <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0.3 }}
               >
                  {patient.currentProgram.programName ===
                     WelkinPrograms.Intake &&
                  patient.currentProgram.currentPhase.name !==
                     IntakeProgramPhases.ExtendedLayoutUnlocked ? (
                     <IntakeLayout />
                  ) : (
                     <ExtendedLayout />
                  )}
               </motion.div>
            ) : (
               <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.7, delay: 0.3 }}
               >
                  <Skeleton h="10rem" rounded="lg" />
               </motion.div>
            )}
         </Box>
      </Flex>
   );
};
