import { getUtcDateFromTz } from '@innerwell/utils';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useDosageConfirmations = () => {
   const { data: dosageConfirmations, ...rest } = useQuery({
      queryKey: queryKeys.dosageConfirmations,
      queryFn: async () => {
         const dateStart = getUtcDateFromTz(DateTime.now().minus({ days: 31 }));
         const dateEnd = getUtcDateFromTz(DateTime.now().plus({ days: 1 }));

         const response = await webApiClient.adsCounter.getDosageConfirmations({
            query: {
               dateStart: dateStart.toISO(),
               dateEnd: dateEnd.toISO(),
            },
         });

         return response.body;
      },
   });

   return { dosageConfirmations: dosageConfirmations ?? [], ...rest };
};
