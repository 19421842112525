import { Button, Flex, type FlexProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { type Swiper as SwiperClass } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/pagination';

import { Icon } from '../Icon';

type Props = {
   children: React.ReactNode[] | React.ReactNode;
} & FlexProps;

export const CardSwiper = ({ children, ...flexProps }: Props) => {
   const [swiper, setSwiper] = useState<SwiperClass | null>(null);

   return (
      <Flex
         {...flexProps}
         pos="relative"
         sx={{
            '--swiper-pagination-bottom': '0px',
            '--swiper-pagination-bullet-width': '16px',
            '--swiper-pagination-bullet-height': '5px',
            '--swiper-pagination-bullet-border-radius': '2px',
            '--swiper-pagination-bullet-horizontal-gap': '2px',

            '--swiper-pagination-color': 'var(--chakra-colors-accent-orange)',
            '--swiper-pagination-bullet-inactive-color':
               'var(--chakra-colors-background-secondary)',

            '--swiper-pagination-bullet-inactive-opacity': '1',

            '.swiper': {
               maxW: { base: 'calc(100vw - 40px)', xl: '100%' },
            },

            '.swiper-container-free-mode > .swiper-wrapper': {
               transitionTimingFunction: 'linear',
            },

            '.swiper-slide': {
               width: { base: '100%', lg: 'calc(50% - 10px)' },
               display: 'flex',
            },
         }}
      >
         <Swiper
            modules={[Pagination]}
            spaceBetween={20}
            slidesPerView="auto"
            pagination={{ clickable: true }}
            onSwiper={setSwiper}
         >
            {children}
         </Swiper>

         {swiper && swiper.slides.length > 2 ? <SwiperNavigation swiper={swiper} /> : null}
      </Flex>
   );
};

const SwiperNavigation = ({ swiper }: { swiper: SwiperClass }) => {
   const handlePrevClick = () => {
      swiper.slidePrev();
   };

   const handleNextClick = () => {
      swiper.slideNext();
   };

   const [isPrevBtnVisible, setIsPrevBtnVisible] = useState(false);
   const [isNextBtnVisible, setIsNextBtnVisible] = useState(true);

   useEffect(() => {
      swiper.on('slideChange', () => {
         setIsPrevBtnVisible(swiper.realIndex > 0);
         setIsNextBtnVisible(
            swiper.realIndex < swiper.pagination.bullets.length - 1,
         );
      });

      return () => {
         swiper.off('slideChange');
      };
   }, [swiper]);

   return (
      <Flex
         display={{ base: 'none', lg: 'flex' }}
         w="full"
         left={0}
         top="50%"
         transform="translateY(-50%)"
         pos="absolute"
         zIndex={10}
         mt={-6}
         pointerEvents="none"
      >
         {isPrevBtnVisible ? <Button
               width="48px"
               height="48px"
               minH="48px"
               px={0}
               variant="solid"
               rounded="full"
               color="white"
               onClick={handlePrevClick}
               aria-label="Previous appointment"
               pointerEvents="auto"
               transform="translateX(-50%)"
               bg="rgba(18, 23, 35, 0.6)"
            >
               <Icon name="chevron-left" w={6} h={6} />
            </Button> : null}
         {isNextBtnVisible ? <Button
               width="48px"
               height="48px"
               minH="48px"
               px={0}
               variant="solid"
               rounded="full"
               color="white"
               onClick={handleNextClick}
               aria-label="Next appointment"
               ml="auto"
               pointerEvents="auto"
               transform="translateX(50%)"
               bg="rgba(18, 23, 35, 0.6)"
            >
               <Icon name="chevron-right" w={6} h={6} />
            </Button> : null}
      </Flex>
   );
};
