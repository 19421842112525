import { WelkinEncounterStatus } from '@innerwell/dtos';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { webApiClient } from '@/api-client/apiClient';
import { ENCOUNTERS_INFO } from '@/utils/appointment-steps.const';
import { REMOVE_APPOINTMENT_AFTER_END_MINUTES } from '@/utils/consts';

import { queryKeys } from '@/types/query-keys';
import { useMissedAppointments } from './useMissedAppointments';

export default function useUpcomingAppointments() {
   const { data: missedAppointments } = useMissedAppointments();

   const { data: appointments, ...rest } = useQuery({
      queryKey: queryKeys.upcomingAppointments,
      queryFn: async () => {
         const response = await webApiClient.appointments.list({
            query: {
               statuses: [
                  WelkinEncounterStatus.Planned,
                  WelkinEncounterStatus.InProgress,
               ],
               sortByCalendar: 'ASC',
               onlyWithoutDispositionCode: true,
            },
         });

         // Remove appointments that are already ended
         //                   (now - remove appt. offset)                   now
         // -------------------------|---------------------------------------|-----------------------
         // appt end date offset xxxx|                                       |
         // NOT included             | included                              | included
         //
         // Example: now is 5:03 PM, appt ends at 5:00 PM, remove appt offset is 10 minutes.
         // endDateOffset is 4:53 PM which is lower than encounter end date of 5:00 PM so appt is visible in the UI.
         // This appointment won't be visible after 5:10 PM.
         const endDateOffset = DateTime.now().minus({
            minute: REMOVE_APPOINTMENT_AFTER_END_MINUTES,
         });

         return (
            response.body
               .filter(
                  (app) =>
                     (app.status === WelkinEncounterStatus.InProgress ||
                        app.status === WelkinEncounterStatus.Planned) &&
                     endDateOffset < DateTime.fromISO(app.endDateTime),
               )
               // Filter out missed appointment cdts
               .filter((appointment) => {
                  const missedAppointment = missedAppointments?.find(
                     (missedAppointment) =>
                        missedAppointment.encounterId === appointment.id,
                  );

                  return !missedAppointment;
               })
               .map((appointment) => {
                  const step = ENCOUNTERS_INFO.find(
                     (s) => s.templateName === appointment.appointmentTemplate,
                  );

                  return {
                     ...appointment,
                     title: step?.title ?? appointment.title,
                  };
               })
         );
      },
   });

   return {
      ...rest,
      appointments: appointments ?? [],
   };
}
