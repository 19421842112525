import { DateTime } from 'luxon';

import { useProgramEncounters } from './useProgramEncounters';

export const useNextProgramEncounterToSchedule = () => {
   const { programEncounters, ...rest } = useProgramEncounters();

   const nextAppointment = programEncounters
      ?.filter((appointment) => {
         return (
            appointment.usedCount < appointment.purchasedCount &&
            Boolean(appointment.schedulingBounds?.earliestStartDate)
         );
      })
      .sort((a, b) => {
         return (
            DateTime.fromISO(a.schedulingBounds?.earliestStartDate as string)
               .toJSDate()
               .getTime() -
            DateTime.fromISO(b.schedulingBounds?.earliestStartDate as string)
               .toJSDate()
               .getTime()
         );
      })
      .at(0);

   return {
      ...rest,
      nextAppointment,
   };
};
