import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';

import { queryKeys } from '@/types/query-keys';

export default function usePatientMedications({
   enabled,
}: {
   enabled?: boolean;
} = {}) {
   const { data: sessionData } = useSession();

   const { data, ...rest } = useQuery({
      queryKey: queryKeys.patientMedications,
      queryFn: async () => {
         const response = await webApiClient.patients.getMedications();
         return response.body;
      },
      enabled: (enabled ?? true) && !!sessionData,
   });

   return {
      ...rest,
      medications: data ?? null,
   };
}
