import { createContext, useContext, useState } from 'react';

export default function CreateStepContext<T extends number>({
   initialStep,
}: {
   initialStep: T;
}) {
   const stepContext = createContext<{
      step: T;
      updateStep: (prop: T) => void;
   }>({
      step: initialStep,
      updateStep: () => {},
   });

   const StepProvider = ({ children }: { children: React.ReactNode }) => {
      const [step, setStep] = useState<T>(initialStep);

      return (
         <stepContext.Provider value={{ step, updateStep: setStep }}>
            {children}
         </stepContext.Provider>
      );
   };

   const useStep = () => {
      const context = useContext(stepContext);
      if (context === undefined) {
         throw new Error('useStep must be used within a Provider');
      }
      return context;
   };

   return {
      StepProvider,
      useStep,
   };
}
