import { Box, type FlexProps } from '@chakra-ui/react';
import { NextImage } from '@innerwell/chakra/images';

import { Icon } from '../Icon';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg';

interface IProps extends FlexProps {
   url: string;
   /**
    * Sizes:
    ** xs - 48px
    ** sm - 88px
    ** md - 112px
    ** lg - 136px
    */
   size: AvatarSize;
   /**
    * Sizes:
    ** xs - 48px
    ** sm - 88px
    ** md - 112px
    ** lg - 136px
    */
   desktopSize?: AvatarSize;

   variant?: 'circle' | 'square';
   hasVerifiedBadge?: boolean;
}
const getSizes = (size: AvatarSize) => {
   switch (size) {
      case 'xs':
         return { image: '60px', badge: '20px' };
      case 'sm':
         return { image: '82px', badge: '26px' };
      case 'md':
         return { image: '112px', badge: '32px' };
      case 'lg':
         return { image: '136px', badge: '36px' };
   }
};

const CustomAvatar: React.FC<IProps> = ({
   size,
   desktopSize,
   url,
   hasVerifiedBadge,
   variant = 'circle',
   ...props
}) => {
   const sizes = getSizes(size);
   const desktopSizes = desktopSize ? getSizes(desktopSize) : sizes;

   return (
      <Box pos="relative" {...props}>
         <NextImage
            src={url}
            w={{ base: sizes.image, lg: desktopSizes.image }}
            h={{ base: sizes.image, lg: desktopSizes.image }}
            imgWidth={150}
            imgHeight={150}
            borderRadius={variant === 'circle' ? 'full' : '18px'}
            alt="Avatar"
         />

         {hasVerifiedBadge ? <Box
               pos="absolute"
               right={0}
               bottom={0}
               transform={{
                  base: 'translateY(10%)',
                  lg: 'translateY(5%)',
               }}
            >
               <Icon
                  name="verified-badge"
                  color="accent.green"
                  w={{ base: sizes.badge, lg: desktopSizes.badge }}
                  h={{ base: sizes.badge, lg: desktopSizes.badge }}
                  flexShrink={0}
               />
            </Box> : null}
      </Box>
   );
};

export default CustomAvatar;
