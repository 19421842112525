import {
   Box,
   Button,
   Flex,
   Text,
   Tooltip,
   useDisclosure,
} from '@chakra-ui/react';
import {
   CdtAdsOriginEnum,
   type ConfirmDosageDto,
   CustomerIOEvents,
} from '@innerwell/dtos';
import { getErrorMessage } from '@innerwell/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useAdsCounter } from '@/hooks/react-query/useAdsCounter';
import { useConfirmDosage } from '@/hooks/react-query/useConfirmDosage';
import { useDosageConfirmations } from '@/hooks/react-query/useDosageConfirmations';
import { useUpdateMedicalAdsCounter } from '@/hooks/react-query/useUpdateMedicalAdsCounter';
import useThemedToast from '@/hooks/useThemedToast';

import ConfirmDosageModal from '@/components/Modals/ConfirmDosageModal';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import adsLocalStorage from '@/utils/adsLocalStorage';

import { BackgroundNoisePattern } from '../BackgroundNoisePattern';
import { Icon } from '../Icon';
import { Illustration } from '../Illustration';
import { MEDICAL_ADS_MIN_WINDOW_HOURS } from '@/utils/consts';

export const StartKetamineExperienceCard = () => {
   const { data: sessionData } = useSession();
   const { toastError } = useThemedToast();
   const { data: currentAds, isFetching: isAdsFetching } = useAdsCounter();

   const nextAdsCount = (currentAds?.count || 0) + 1;

   const { dosageConfirmations, isFetching: isDosageConfirmationsFetching } =
      useDosageConfirmations();

   const adsDoneForToday = useMemo(() => {
      if (isDosageConfirmationsFetching) {
         return null;
      }

      if (!dosageConfirmations.length) {
         return false;
      }

      return dosageConfirmations.some(
         (item) => item.date === DateTime.now().toISODate(),
      );
   }, [dosageConfirmations, isDosageConfirmationsFetching]);

   const { mutate: updateAdsOutsidePlatform, status: updateAdsStatus } =
      useUpdateMedicalAdsCounter({
         nextAdsCount,
         onError: (res) => {
            toastError(getErrorMessage(res));
         },
      });

   const { mutate: confirmDosage } = useConfirmDosage();

   const confirmDosageModal = useDisclosure();

   const handleCompletedOutsidePlatformClick = () => {
      confirmDosageModal.onOpen();
   };

   const handleConfirmDosageSubmit = (data: ConfirmDosageDto) => {
      updateAdsOutsidePlatform();
      confirmDosage(data);

      if (sessionData) {
         webApiClient.customerio.track({
            body: {
               id: sessionData['cognito:username'],
               name: CustomerIOEvents.DosingSessionStarted,
               data: {
                  time_started: DateTime.utc().toString(),
                  time_ended: null,
                  counter: nextAdsCount,
                  platform_type: CdtAdsOriginEnum.OutsidePlatform,
               },
            },
         });
      }
   };

   const nextAdsInHours = useMemo(() => {
      if (
         !currentAds ||
         !currentAds.cdtValue?.jsonBody['cdtf-ads-1'] ||
         adsDoneForToday === false
      ) {
         return null;
      }

      const dateNow = DateTime.now();
      const lastAdsDate = DateTime.fromISO(
         currentAds.cdtValue.jsonBody.updated_at,
      );

      const hoursDiff = dateNow.diff(lastAdsDate, 'hours').toObject().hours;

      if (!hoursDiff) {
         return null;
      }

      return Math.max(
         Math.min(24, MEDICAL_ADS_MIN_WINDOW_HOURS - Math.floor(hoursDiff)),
         1,
      );
   }, [adsDoneForToday, currentAds]);

   const handleDosingFlowStart = () => {
      adsLocalStorage.clear();
      const flowTimeStart = new Date();
      adsLocalStorage.setDosingNumber(nextAdsCount);
      adsLocalStorage.setFlowTimeStart(flowTimeStart);

      if (sessionData) {
         webApiClient.customerio.track({
            body: {
               id: sessionData['cognito:username'],
               name: CustomerIOEvents.DosingSessionStarted,
               data: {
                  time_ended: null,
                  time_started: DateTime.fromJSDate(flowTimeStart)
                     .toUTC()
                     .toString(),
                  counter: nextAdsCount,
                  platform_type: CdtAdsOriginEnum.InsidePlatform,
               },
            },
         });
      }
   };

   const tooltipMessage =
      adsDoneForToday === true && typeof nextAdsInHours === 'number'
         ? `Please wait for ${nextAdsInHours} ${
              nextAdsInHours > 1 ? 'hours' : 'hour'
           } before you can take your medicine again.`
         : null;

   return (
      <Box>
         <ConfirmDosageModal
            onFormSubmit={handleConfirmDosageSubmit}
            {...confirmDosageModal}
         />

         <Box
            bg="white"
            borderRadius={12}
            boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
            pos="relative"
            overflow="hidden"
         >
            <BackgroundNoisePattern />
            <Illustration
               pos="absolute"
               right={0}
               top={{ base: 0, lg: 4 }}
               w={{ base: '130px', lg: '210px' }}
               h={{ base: ' 80px', lg: '120px' }}
               alt=""
               src="/illustrations/self-dosing.svg"
            />
            <Flex
               direction="column"
               justifyContent="flex-start"
               alignItems="flex-start"
               my={{ base: 6, lg: 6 }}
               px={{ base: 6, lg: 10 }}
               mt={{
                  base: 4,
                  lg: 6,
               }}
               w="full"
               gap={3}
            >
               <Flex justifyContent="flex-start" alignItems="center">
                  <Flex flexDir="column" alignItems="flex-start">
                     <Text
                        fontWeight={500}
                        size="leadText"
                        color="text.primary"
                        pr={{ base: 20, lg: 0 }}
                     >
                        Start your Ketamine Experience
                     </Text>
                  </Flex>
               </Flex>

               <Flex gap={{ base: 4, lg: 8 }}>
                  <Flex
                     gap={2}
                     direction="column"
                     color="text.primary"
                     maxW="630px"
                  >
                     <Text size="body" pr={{ base: 0, sm: 24, lg: 24 }}>
                        {`We’ve included everything you need to safely
                                 and comfortably prepare for your session.`}
                     </Text>
                  </Flex>
               </Flex>

               <Flex
                  flexDir={{ base: 'column', lg: 'row' }}
                  alignItems={{
                     base: 'flex-start',
                     lg: 'center',
                  }}
                  gap={{ base: 0, lg: 6 }}
                  mt={{ base: 2, lg: 2 }}
               >
                  <Tooltip
                     textColor="white"
                     isDisabled={!tooltipMessage}
                     label={tooltipMessage}
                     shouldWrapChildren
                     hasArrow
                  >
                     <NextLinkButton
                        size={{ base: 'xs', lg: 'sm' }}
                        minW={{ base: 'full', lg: 'auto' }}
                        href={
                           adsLocalStorage.isWelcomePopupSeen()
                              ? '/ads/form'
                              : '/ads'
                        }
                        rightIcon={<Icon name="arrow-right" w={6} h={6} />}
                        onClick={handleDosingFlowStart}
                        isDisabled={adsDoneForToday === true}
                        isLoading={
                           updateAdsStatus === 'pending' ||
                           isAdsFetching ||
                           isDosageConfirmationsFetching
                        }
                     >
                        Start your experience
                     </NextLinkButton>
                  </Tooltip>
                  <Button
                     mt={{ base: 3, lg: 0 }}
                     variant="link"
                     fontSize="base"
                     position="relative"
                     minW={{ base: 'full', lg: 'auto' }}
                     zIndex={1}
                     onClick={handleCompletedOutsidePlatformClick}
                  >
                     Record an offline experience
                  </Button>
               </Flex>
               {tooltipMessage ? (
                  <Text
                     fontSize={{ base: 'xxs', lg: 'xs' }}
                     display={{ lg: 'none' }}
                     color="neutral.500"
                  >
                     * {tooltipMessage}
                  </Text>
               ) : null}
            </Flex>
         </Box>
      </Box>
   );
};
