import { type Transition, type Variants } from 'framer-motion';

export const getStaggerVariants = (transitionOptions: Transition = {}) =>
   ({
      container: {
         hidden: { opacity: 0 },
         show: {
            opacity: 1,
            transition: {
               staggerChildren: 0.3,
               ease: 'easeInOut',
               ...transitionOptions,
            },
         },
      },
      child: {
         hidden: { opacity: 0, y: 10 },
         show: {
            opacity: 1,
            y: 0,
            transition: {
               duration: 0.6,
               ease: 'easeInOut',
            },
         },
      },
   }) satisfies Record<string, Variants>;
