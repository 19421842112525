import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

export const InfoText = (props: TextProps) => {
   return (
      <Text
         mt={4}
         pr={{ lg: 24 }}
         color="text.primary"
         size="paragraphSmall"
         opacity={0.6}
         {...props}
      />
   );
};
