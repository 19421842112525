import { Flex } from '@chakra-ui/react';

import { PatientMissedAppointmentCard } from './PatientMissedAppointmentCard';
import { PatientPaymentFailedCard } from './PatientPaymentFailedCard';
import { PatientPhaseLockedCard } from './PatientPhaseLockedCard';
import { LockedStatusType } from './types';
import { type LockedStatus } from './useUserAccess';

/**
 * Handles the rendering of the PatientPhaseLockedCard and PatientPaymentLockedCard
 */
export const PatientLocked = ({ statuses }: { statuses: LockedStatus[] }) => {
   return (
      <Flex flexDir="column" gap={4}>
         {statuses.map((status) => {
            if (status.type === LockedStatusType.PAYMENT_FAILED) {
               return (
                  <PatientPaymentFailedCard
                     key={status.type}
                     paymentFailedCdt={status.paymentFailedCdt}
                  />
               );
            }

            if (status.type === LockedStatusType.PHASE) {
               return (
                  <PatientPhaseLockedCard
                     key={status.type}
                     explanation={status.explanation}
                  />
               );
            }

            if (status.type === LockedStatusType.MISSED_APPOINTMENT) {
               return (
                  <PatientMissedAppointmentCard
                     key={status.type}
                     missedAppointment={status.missedAppointment}
                  />
               );
            }
         })}
      </Flex>
   );
};
