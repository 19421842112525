import { type CdtContactConsent, type PatientCdtsItem } from '@innerwell/dtos';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

type UseContactConsentProps = Omit<
   UseQueryOptions<unknown, unknown, PatientCdtsItem<CdtContactConsent>>,
   'queryKey' | 'queryFn' | 'initialData'
>;

export const useContactConsent = (props: UseContactConsentProps) => {
   return useQuery({
      queryKey: queryKeys.contactConsent,
      queryFn: async () => {
         const response = await webApiClient.contactConsent.getConsent();
         return response.body;
      },
      ...props,
   });
};
