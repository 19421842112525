import { Box, type BoxProps, Flex, Skeleton } from '@chakra-ui/react';
import {
   IntakeProgramPhases,
   WelkinEncounterTemplateNames,
} from '@innerwell/dtos';

import { useNextProgramEncounterToSchedule } from '@/hooks/react-query/useNextProgramEncounterToSchedule';
import useUpcomingAppointments from '@/hooks/react-query/useUpcomingAppointments';
import { useAppointmentLoadingStatus } from '@/hooks/useAppointmentLoadingStatus';

import { ErrorCard } from '@/components/Cards/ErrorCard';
import ScheduleNextAppointmentCard from '@/components/Cards/ScheduleNextAppointmentCard';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { JoinAppointmentCard } from '@/components/Cards/JoinAppointment/JoinAppointmentCard';
import { Card } from '@/components/Cards/components/Card';

const MedicalConsultStep: React.FC<BoxProps> = (props) => {
   const {
      programPhase: { phase },
   } = usePatientProgram();

   const { appointments, status: upcomingAppointmentsStatus } =
      useUpcomingAppointments();

   // NOTE(AHC-246): changed to show the first appointment here. We might have multiple intake appointments types.
   // const appointment = appointments.find((appointment) => {
   //    return appointment.appointmentTemplate === encounterTemplate;
   // });
   const upcomingAppointment = appointments.at(0);

   // TODO: is this necessary?
   const appointmentLoadingStatus = useAppointmentLoadingStatus();

   const { nextAppointment, status: nextAppointmentStatus } =
      useNextProgramEncounterToSchedule();

   return (
      <Box {...props}>
         <Skeleton
            minHeight="270px"
            isLoaded={
               upcomingAppointmentsStatus !== 'pending' &&
               appointmentLoadingStatus === null &&
               nextAppointmentStatus !== 'pending'
            }
            borderRadius="md"
         >
            <Flex flexDir="column" gap={4}>
               {upcomingAppointment ? (
                  <JoinAppointmentCard
                     appointment={upcomingAppointment}
                     title={upcomingAppointment.title}
                  />
               ) : null}
               {/* TODO: Add scheduling rules for MYT in Intake, until then we hide it */}
               {nextAppointment &&
               nextAppointment.templateName !==
                  WelkinEncounterTemplateNames.MeetYourTherapistALaCarte ? (
                  <ScheduleNextAppointmentCard appointment={nextAppointment} />
               ) : null}

               {/* If next appointment is available, don't show anything as */}
               {/* patients have their next step - scheduling the appointment. // */}
               {/* If no next appt to schedule nor upcoming appointments are available, show error card. */}
               {!nextAppointment && !upcomingAppointment && (
                  <ErrorCard
                     text="Cannot find the appointment for the medical consult. Please contact support."
                     minHeight="270px"
                     maxWidth="auto"
                  />
               )}
            </Flex>
         </Skeleton>

         {phase === IntakeProgramPhases.MedicalConsultFinished && (
            <Card>
               <Card.Image
                  src="/images/medical-consult-woman.svg"
                  alt="Card image"
               />
               <Card.Title>Medical Review in Progress</Card.Title>
               <Card.Text>
                  Please give us 1-2 business days to review your case notes and
                  confirm treatment eligibility. Once confirmed, we’ll be in
                  touch when your prescription is on the way.
               </Card.Text>
            </Card>
         )}
      </Box>
   );
};

export default MedicalConsultStep;
