import { Tooltip as ChakraTooltip, type TooltipProps } from '@chakra-ui/react';

export const Tooltip = (props: TooltipProps) => {
   return (
      <ChakraTooltip
         textColor="white"
         hasArrow
         bg="text.primary"
         borderRadius="8px"
         maxW="260px"
         py={2}
         ml={{ base: 2, lg: 0 }}
         px={{ base: 2, lg: 3 }}
         fontWeight={500}
         {...props}
      />
   );
};
