import { WelkinEncounterTemplateNames } from '@innerwell/dtos';

export interface IAppointmentStepInfo {
   title: string;
   templateName: WelkinEncounterTemplateNames;
}

export const ENCOUNTERS_INFO: IAppointmentStepInfo[] = [
   {
      title: 'Meet Your Therapist',
      templateName: WelkinEncounterTemplateNames.MeetYourTherapistALaCarte,
   },
   {
      title: 'Integration Session',
      templateName: WelkinEncounterTemplateNames.Integration30MinALaCarte,
   },
   {
      title: 'Integration Session',
      templateName: WelkinEncounterTemplateNames.Integration50MinALaCarte,
   },
   {
      title: 'Preparation Session',
      templateName: WelkinEncounterTemplateNames.Preparation30MinALaCarte,
   },
   {
      title: 'Preparation Session',
      templateName: WelkinEncounterTemplateNames.Preparation50MinALaCarte,
   },
   {
      title: 'Psychotherapist-Led Ketamine Session',
      templateName: WelkinEncounterTemplateNames.SupervisedDosingALaCarte,
   },
   {
      title: 'Meet Your Therapist',
      templateName: WelkinEncounterTemplateNames.TherapistIntake,
   },
   {
      title: 'Medical Check-in',
      templateName: WelkinEncounterTemplateNames.MedicalCheckIn,
   },
   {
      title: 'Medical Consult',
      templateName: WelkinEncounterTemplateNames.MedicalConsult,
   },
   {
      title: 'Medical Check-in #1',
      templateName: WelkinEncounterTemplateNames.MedicalFollowUp,
   },
   {
      title: 'Medical Check-in #2',
      templateName: WelkinEncounterTemplateNames.MedicalFollowUp2,
   },
   {
      title: 'Medical Check-in #3',
      templateName: WelkinEncounterTemplateNames.MedicalFollowUp3,
   },

   {
      title: 'Integration Therapy',
      templateName: WelkinEncounterTemplateNames.IntegrationSession1,
   },
   {
      title: 'Integration Therapy',
      templateName: WelkinEncounterTemplateNames.IntegrationSession2,
   },
   {
      title: 'Integration Therapy',
      templateName: WelkinEncounterTemplateNames.IntegrationSession3,
   },
   {
      title: 'Integration Therapy',
      templateName: WelkinEncounterTemplateNames.IntegrationSession4,
   },

   {
      title: '1st KAP Session',
      templateName: WelkinEncounterTemplateNames.TherapistSDS1,
   },
   {
      title: '2nd KAP Session',
      templateName: WelkinEncounterTemplateNames.TherapistSDS2,
   },

   {
      title: 'Capstone Appointment',
      templateName: WelkinEncounterTemplateNames.CapstoneAppointment,
   },
   {
      title: 'Psychotherapy Session',
      templateName: WelkinEncounterTemplateNames.FreeTherapySession,
   },
];
