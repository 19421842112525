import { Box, type BoxProps, Text } from '@chakra-ui/react';
import type { ReactNode } from 'react';

type BadgeProps = {
   children: ReactNode;
} & BoxProps;

export const Badge = ({ children, ...boxProps }: BadgeProps) => {
   return (
      <Box
         pos="absolute"
         right={0}
         top={0}
         bg="accent.orange"
         borderBottomLeftRadius="10px"
         px={{ base: 4, lg: 7 }}
         py={{ base: 1.5, lg: 2.5 }}
         zIndex={2}
         {...boxProps}
      >
         <Text size="md" color="white" opacity={0.75} fontWeight={600}>
            {children}
         </Text>
      </Box>
   );
};
