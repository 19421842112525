import { type AppointmentItem } from '@innerwell/dtos';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams , useRouter } from 'next/navigation';
import { useEffect } from 'react';

import { useScheduling } from '@/contexts/scheduling-context';

import { queryKeys } from '@/types/query-keys';

export const useAppointmentLoadingStatus = () => {
   const { appointmentLoadingStatus, setAppointmentLoadingStatus } =
      useScheduling();

   const searchParams = useSearchParams();
   const router = useRouter();

   const queryClient = useQueryClient();

   const appointmentRescheduled = searchParams.get('appointment_rescheduled');

   useEffect(() => {
      if (typeof appointmentRescheduled === 'string') {
         setAppointmentLoadingStatus({
            type: 'reschedule',
            appointmentId: appointmentRescheduled,
         });

         const nextSearchParams = new URLSearchParams(searchParams.toString());
         nextSearchParams.delete('appointment_rescheduled');

         router.replace(`/?${nextSearchParams.toString()}`);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (appointmentLoadingStatus) {
         const previousAppointments =
            queryClient.getQueryData<AppointmentItem[]>([
               'upcoming_appointments',
            ]) ?? [];

         const previousAppointment = previousAppointments?.find(
            (app) => app.id === appointmentLoadingStatus.appointmentId,
         );

         const interval = setInterval(() => {
            queryClient.refetchQueries({
               queryKey: queryKeys.upcomingAppointments,
            });

            const currentAppointments =
               queryClient.getQueryData<AppointmentItem[]>([
                  'upcoming_appointments',
               ]) ?? [];

            if (appointmentLoadingStatus.type === 'reschedule') {
               const currentAppointment = currentAppointments?.find(
                  (app) => app.id === appointmentLoadingStatus.appointmentId,
               );
               if (
                  previousAppointment?.startDateTime !==
                  currentAppointment?.startDateTime
               ) {
                  setAppointmentLoadingStatus(null);
                  clearInterval(interval);
                  
               }
            }
         }, 3000);

         return () => {
            clearInterval(interval);
         };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [appointmentLoadingStatus, queryClient]);

   return appointmentLoadingStatus;
};
