const LOCALSTORAGE_HIDE_BUY_ADDONS_BANNER_KEY = 'hide-buy-addon-banner';
const LOCALSTORAGE_ADDON_WELCOME_BANNER_SEEN_KEY = 'addon-welcome-banner-seen';
const LOCALSTORAGE_INTAKE_COMPLETED_STEPS_HIDDEN_KEY =
   'intake-completed-steps-hidden';
const LOCALSTORAGE_IS_PURCHASE_ANNOUNCEMENT_STRIP_HIDDEN_KEY =
   'purchase-announcement-strip-hidden';
const LOCALSTORAGE_PURCHASE_PRODUCT_CATEGORY_KEY = 'purchase-product-category';

const optionsLocalStorage = {
   markAddonWelcomeBannerSeen: () => {
      window.localStorage.setItem(
         LOCALSTORAGE_ADDON_WELCOME_BANNER_SEEN_KEY,
         '1',
      );
   },

   getIsAddonWelcomeBannerSeen: () => {
      return (
         window.localStorage.getItem(
            LOCALSTORAGE_ADDON_WELCOME_BANNER_SEEN_KEY,
         ) === '1'
      );
   },

   hideBuyAddonBanner: () => {
      window.localStorage.setItem(LOCALSTORAGE_HIDE_BUY_ADDONS_BANNER_KEY, '1');
   },

   getIsBuyAddonBannerHidden: () => {
      return window.localStorage.getItem(
         LOCALSTORAGE_HIDE_BUY_ADDONS_BANNER_KEY,
      );
   },

   getIsIntakeCompletedStepsHidden: () => {
      return (
         window.localStorage.getItem(
            LOCALSTORAGE_INTAKE_COMPLETED_STEPS_HIDDEN_KEY,
         ) === '1'
      );
   },

   setIsIntakeCompletedStepsHidden: (value: boolean) => {
      window.localStorage.setItem(
         LOCALSTORAGE_INTAKE_COMPLETED_STEPS_HIDDEN_KEY,
         value ? '1' : '0',
      );
   },

   getPurchaseProductCategory: () => {
      return window.localStorage.getItem(
         LOCALSTORAGE_PURCHASE_PRODUCT_CATEGORY_KEY,
      );
   },

   setPurchaseProductCategory: (productCategory: string) => {
      window.localStorage.setItem(
         LOCALSTORAGE_PURCHASE_PRODUCT_CATEGORY_KEY,
         productCategory,
      );
   },

   isPurchaseAnnouncementStripHidden: () => {
      return (
         window.localStorage.getItem(
            LOCALSTORAGE_IS_PURCHASE_ANNOUNCEMENT_STRIP_HIDDEN_KEY,
         ) === '1'
      );
   },

   markPurchaseAnnouncementStripHidden: () => {
      window.localStorage.setItem(
         LOCALSTORAGE_IS_PURCHASE_ANNOUNCEMENT_STRIP_HIDDEN_KEY,
         '1',
      );
   },
};

export default optionsLocalStorage;
