import { type LockedExplanation } from '@/utils/lockedPhases';

import { Card } from '../components/Card';

export const PatientPhaseLockedCard = ({
   explanation,
}: {
   explanation: LockedExplanation;
}) => {
   const Icon = explanation.icon ?? null;

   return (
      <Card minH="none" bg="background.secondary">
         <Card.Title
            fontWeight={600}
            display="flex"
            gap={3}
            alignItems="center"
         >
            {Icon ? (
               <Icon
                  w={{ base: '60px', lg: '80px' }}
                  h={{ base: '60px', lg: '80px' }}
               />
            ) : null}
            {explanation.heading}
         </Card.Title>
         <Card.Text fontSize="lg" pr={4}>
            {explanation.text}
         </Card.Text>
         {explanation.cta ? (
            <Card.ButtonGroup>{explanation.cta}</Card.ButtonGroup>
         ) : null}
      </Card>
   );
};
