import {
   chakra,
   Flex,
   type RadioProps,
   Text,
   useRadio,
} from '@chakra-ui/react';

import { Icon } from '@/components/Icon';

import { type MoodIconName } from '@/utils/weekly-mood-options';

interface IProps extends RadioProps {
   text: string;
   iconName: MoodIconName;
   backgroundColor: string | undefined;
   backgroundHoverColor: string | undefined;
   mainColor: string | undefined;
}

const EmotionRadioCheckbox: React.FC<IProps> = ({
   text,
   iconName,
   backgroundColor,
   backgroundHoverColor,
   mainColor,
   ...radioProps
}) => {
   const {
      state: { isChecked, isHovered },
      getInputProps,
      getRadioProps,
      htmlProps,
      getLabelProps,
   } = useRadio(radioProps);

   return (
      <chakra.label {...htmlProps}>
         <input {...getInputProps({})} hidden />
         <Flex
            {...getRadioProps()}
            mb={3}
            pl={2}
            pr={6}
            borderRadius="32px"
            minH="56px"
            justifyContent="space-between"
            alignItems="center"
            cursor="pointer"
            bg={
               isHovered
                  ? backgroundHoverColor
                  : isChecked
                    ? mainColor
                    : backgroundColor
            }
         >
            <Flex alignItems="center" gap={5} {...getLabelProps()}>
               <Icon
                  name={isHovered ? `${iconName}-fill` : iconName}
                  color={mainColor}
                  w={7}
                  h={7}
               />
               <Text
                  size="paragraphLarge"
                  lineHeight="135%"
                  fontWeight={500}
                  textAlign="center"
                  color={isChecked && !isHovered ? 'white' : 'text.secondary'}
               >
                  {text}
               </Text>
            </Flex>

            <Icon
               name="chevron-right"
               w={3.5}
               h={3.5}
               strokeWidth={2}
               color="element.tertiary"
            />
         </Flex>
      </chakra.label>
   );
};

export default EmotionRadioCheckbox;
