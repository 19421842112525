import { Flex, type FlexProps } from '@chakra-ui/react';

type Props = {
   children: React.ReactNode | string;
} & FlexProps;

export const InfoDialog = ({ children, ...props }: Props) => {
   return (
      <Flex
         bg="background.secondary"
         borderRadius="8px"
         fontSize={{
            base: 'xxs',
            lg: 'xs',
         }}
         color="background.fourth"
         p={2}
         px={3}
         alignSelf="flex-start"
         {...props}
      >
         {children}
      </Flex>
   );
};
