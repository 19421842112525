import type { TextProps as ChakraTextProps } from '@chakra-ui/react';
import { Text as ChakraText } from '@chakra-ui/react';

type TextProps = {
   hasRightImage?: boolean;
} & ChakraTextProps;

export const Text = ({ hasRightImage, ..._textProps }: TextProps) => {
   const { children, ...textProps } = _textProps;

   return (
      <ChakraText
         color="text.secondary"
         position="relative"
         zIndex={1}
         minW={{ xl: '580px' }}
         mt={3}
         pr={{
            lg: hasRightImage ? '220px' : '100px',
         }}
         {...textProps}
      >
         {children}
      </ChakraText>
   );
};
