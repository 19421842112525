import { useMutation, useQueryClient } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';
import { queryKeys } from '@/types/query-keys';
import { type AppointmentItem, type CancelAppointmentIntent } from '@innerwell/dtos';

export const useCancelAppointment = () => {
   const queryClient = useQueryClient();

   return useMutation({
      mutationFn: async ({
         appointmentId,
         intent,
      }: {
         appointmentId: string;
         intent: CancelAppointmentIntent;
      }) => {
         const response = await webApiClient.appointments.cancel({
            body: { encounterId: appointmentId, intent },
         });

         return response.body;
      },

      onSuccess: (response, { appointmentId }) => {
         if (!response.ok) {
            return;
         }

         // If we get a missedPaymentCdtId, we need to redirect the user to the purchase missed appointment page
         // so we skip the queryClient.invalidateQueries and removing the appointment from the list
         if (response.missedPaymentCdtId) {
            return;
         }

         queryClient.setQueryData<AppointmentItem[]>(
            queryKeys.upcomingAppointments,
            (appointments) => {
               if (!appointments) {
                  return;
               }

               return appointments.filter(
                  (appointment) => appointment.id !== appointmentId,
               );
            },
         );

         queryClient.invalidateQueries({
            queryKey: queryKeys.appointments,
         });

         queryClient.invalidateQueries({
            queryKey: queryKeys.appointmentBank,
         });
      },
   });
};
