import { type FlexProps } from '@chakra-ui/layout';
import { Card } from './components/Card';

export const PatientDischargedCard = (props: FlexProps) => {
   return (
      <Card minH="none" bg="background.secondary" maxW="full" {...props}>
         <Card.Title
            fontWeight={600}
            display="flex"
            gap={3}
            alignItems="center"
         >
            Your Patient Chart is Closed
         </Card.Title>
         <Card.Text pr={0}>
            {`It's been over 8 months since your last appointment at Innerwell. As
            a result, we've closed your chart. We will not be able to provide
            any further prescriptions or medication refills, unless you
            reinitiate care with Innerwell. To resume your care, simply schedule
            a Psychiatry or Psychotherapy consultation, and we’ll reopen your
            chart. In the meantime, you are welcome to continue to use the
            Innerwell app.`}
         </Card.Text>
         <Card.Text>
            We want to remind you that it is your responsibility to arrange for
            any necessary follow-up care or continued psychiatric or
            psychotherapeutic treatment.
         </Card.Text>
         <Card.Text>If you have questions please contact support.</Card.Text>
         <Card.ButtonGroup>
            <Card.ButtonLink href="/support">Support</Card.ButtonLink>
         </Card.ButtonGroup>
      </Card>
   );
};
