import {
   Accordion,
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Box,
   Flex,
   IconButton,
   Skeleton,
   Text,
   useDisclosure,
} from '@chakra-ui/react';
import { useRouter, useSearchParams } from 'next/navigation';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { Icon } from '../Icon';
import { BottomRightCircles } from '../Images';
import DailyMoodTrackingModal from '../Modals/DailyMoodTrackingModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type ProgressDto } from '@innerwell/dtos';
import { DateTime } from 'luxon';
import useThemedToast from '@/hooks/useThemedToast';
import { getUtcDateFromTz, getWeekStart } from '@innerwell/utils';
import { motion } from 'framer-motion';
import { type QueryStatus } from '@tanstack/react-query';

const MoodTrackingMessages = [
   `You’ll learn a lot about yourself by tracking your emotions and moods, and
   it’s a powerful way to take care of your mental health.`,

   `No matter how you feel, we’re here for you. By tracking your moods over time,
   your Care Team will gain a deeper understanding of your needs.`,

   `Did you know that the physiological lifespan of an emotion in the brain is
   90 seconds? Identifying an emotion can help you let go of it.`,

   `By regularly tracking your moods, you’ll more clearly see the relationship
   between your thoughts, emotions, and behavior.`,

   `Remember to take time during the day to check in with how you’re feeling.
   Creating this space can help you identify emotional triggers.`,

   `Emotions ebb and flow, whereas moods are more persistent. Daily mood tracking
   allows you to see the connection between your emotions and moods.`,

   `Labeling your emotions regularly helps you become more in touch with them.
   Acknowledging your moods daily helps you better understand them.`,
];

const placeholderDays = Array.from({ length: 7 }, (_, index) => ({
   date: getUtcDateFromTz(getWeekStart(DateTime.now()))
      .plus({ days: index })
      .toISODate(),
   score: null,
}));

export const MoodTracking = ({
   progress,
   queryStatus,
}: {
   progress: ProgressDto | null;
   queryStatus: QueryStatus;
}) => {
   const [dailyMoodTrackDate, setDailyMoodTrackDate] = useState<string | null>(
      null,
   );

   const searchParams = useSearchParams();
   const router = useRouter();
   const { toastSuccess } = useThemedToast();

   const isWeeklyPauseFinished =
      searchParams.get('weekly-pause-finished') === '1';

   const initialMoodTrackingModal = useDisclosure();

   const handleOpenMoodTrackingModalWithDate = useCallback(
      (date: string) => {
         setDailyMoodTrackDate(date);
         initialMoodTrackingModal.onOpen();
      },
      [initialMoodTrackingModal],
   );

   const handleFinishedDailyMoodTracking = () => {
      // refetchMoodTracking();
   };

   const sendWeeklyMoodTracking =
      !!progress?.sendWeeklyPause && !isWeeklyPauseFinished;

   const days = progress?.segments ?? placeholderDays;

   const daysTracked = useMemo(
      () =>
         days.reduce((acc, day) => {
            return acc + (day.score ? 1 : 0);
         }, 0),
      [days],
   );

   const isTodayFilledIn = useMemo(() => {
      const currentDatetime = DateTime.local();
      const today = currentDatetime.toISODate();
      const day = days.find((d) => d.date === today);
      return day?.score ? true : false;
   }, [days]);

   useEffect(() => {
      if (searchParams.get('open') === 'daily-mood-tracking') {
         if (!isTodayFilledIn) {
            handleOpenMoodTrackingModalWithDate(DateTime.local().toISODate());
         } else {
            toastSuccess("You've already tracked your daily mood today!");
         }

         const nextSearchParams = new URLSearchParams(searchParams.toString());
         nextSearchParams.delete('open');

         router.replace(`/?${nextSearchParams.toString()}`);
      }
   }, [
      handleOpenMoodTrackingModalWithDate,
      isTodayFilledIn,
      router,
      searchParams,
      toastSuccess,
   ]);

   return (
      <>
         <Flex flexDir="column" gap={{ base: 4, lg: 6 }}>
            <Flex
               alignItems="center"
               bg="rgba(255, 255, 255, 0.1)"
               borderRadius={12}
               pr={{ lg: 6 }}
               px={{ base: 4, lg: 7 }}
               gap={{ base: 2, lg: 8 }}
               flexDir={{ base: 'column', lg: 'row' }}
            >
               <Box w="full" flex={1} minW={{ lg: '450px' }}>
                  <Flex
                     direction={{ base: 'column', lg: 'row' }}
                     pt={{ base: 4, lg: 6 }}
                     pb={{ base: 4, lg: 6 }}
                     justifyContent="space-between"
                  >
                     <Text size="captionSmall" letterSpacing={0.5}>
                        Daily mood tracking
                     </Text>
                     {queryStatus !== 'pending' && (
                        <Text
                           size={{ base: 'paragraphSmall', lg: 'paragraph' }}
                        >
                           {daysTracked} of 7 this week
                        </Text>
                     )}
                  </Flex>

                  <Flex pb={4} justifyContent="space-between">
                     {queryStatus === 'pending' ? (
                        <Skeleton
                           w="full"
                           h={{ base: '3.5rem', lg: '5.1rem' }}
                           rounded="lg"
                        />
                     ) : (
                        days.map((day) => {
                           const currentDay = DateTime.fromISO(
                              day.date,
                           ).hasSame(DateTime.local(), 'day');

                           const currentDayIsFilledIn = currentDay && day.score;

                           return (
                              <Flex
                                 key={day.date}
                                 direction="column"
                                 alignItems="center"
                                 justifyContent="space-between"
                              >
                                 {currentDay && !currentDayIsFilledIn ? (
                                    <IconButton
                                       icon={
                                          <Icon
                                             name="plus-pin"
                                             w={{
                                                base: '2.62rem',
                                                lg: '4.37rem',
                                             }}
                                             h={{
                                                base: '3.37rem',
                                                lg: '4.75rem',
                                             }}
                                             color="accent.orange"
                                          />
                                       }
                                       variant="link"
                                       aria-label="days"
                                       mb={-4}
                                       top={-3}
                                       onClick={() =>
                                          handleOpenMoodTrackingModalWithDate(
                                             day.date,
                                          )
                                       }
                                    />
                                 ) : (
                                    <Flex
                                       alignItems="center"
                                       justifyContent="center"
                                       width={{ base: '30px', lg: '44px' }}
                                       height={{ base: '30px', lg: '44px' }}
                                       bg={
                                          day.score
                                             ? '#9DE3E3'
                                             : 'rgba(18, 23, 35, 0.35)'
                                       }
                                       borderRadius="50%"
                                       mb={2}
                                    >
                                       {day.score ? (
                                          <Icon
                                             name="checkmark"
                                             w={{ base: 4, lg: 5 }}
                                             h={{ base: 4, lg: 5 }}
                                             color="black"
                                          />
                                       ) : null}
                                    </Flex>
                                 )}

                                 <Text
                                    display={{ base: 'none', lg: 'block' }}
                                    fontSize="xxs"
                                    fontWeight={600}
                                 >
                                    {DateTime.fromISO(day.date).toFormat(
                                       'cccc',
                                    )}
                                 </Text>
                                 <Text
                                    display={{ lg: 'none' }}
                                    fontSize="xxs"
                                    fontWeight={600}
                                 >
                                    {DateTime.fromISO(day.date).toFormat(
                                       'ccccc',
                                    )}
                                 </Text>
                              </Flex>
                           );
                        })
                     )}
                  </Flex>
               </Box>
               {!isTodayFilledIn && queryStatus !== 'pending' && (
                  <Box
                     maxW={{ lg: '270px' }}
                     w="full"
                     p={3}
                     mt={{ lg: 4 }}
                     mb={{ base: 4, lg: 4 }}
                     borderRadius={6}
                     bg="whiteAlpha.200"
                  >
                     <Text
                        size={{ base: 'paragraphSmall', xl: 'paragraph' }}
                        fontStyle="italic"
                     >
                        {MoodTrackingMessages[daysTracked + 1]}
                     </Text>
                  </Box>
               )}
            </Flex>

            {sendWeeklyMoodTracking && (
               <Box
                  as={motion.div}
                  sx={{
                     '--height': {
                        base: '19.25rem',
                        lg: '15.625rem',
                     },
                  }}
                  initial={{
                     opacity: 0,
                     maxHeight: 0,
                  }}
                  animate={{
                     opacity: 1,
                     maxHeight: 'var(--height)',
                  }}
                  pos="relative"
                  zIndex={4}
               >
                  <Flex
                     flexDir="column"
                     position="absolute"
                     top="-2.05rem"
                     left={5}
                     alignItems="center"
                     justifyContent="center"
                     zIndex={4}
                  >
                     <Icon
                        name="vertical-dashed-line"
                        h={14}
                        w="1px"
                        color="background.primary"
                     />

                     <Box
                        boxSize=".7rem"
                        bg="background.primary"
                        rounded="full"
                        opacity={1}
                        flexShrink={0}
                        flexGrow={0}
                     />
                  </Flex>

                  <Accordion allowToggle defaultIndex={[0]}>
                     <AccordionItem
                        border="none"
                        borderRadius="12px"
                        boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
                     >
                        {({ isExpanded }) => (
                           <>
                              <h2>
                                 <AccordionButton
                                    px={{ base: 3, lg: 4 }}
                                    minH="56px"
                                    pl={{ base: 10, lg: '42px' }}
                                    bg="background.secondary"
                                    _hover={{
                                       bg: 'background.secondary',
                                    }}
                                    borderRadius="12px"
                                    borderBottomLeftRadius={
                                       isExpanded ? 0 : '12px'
                                    }
                                    borderBottomRightRadius={
                                       isExpanded ? 0 : '12px'
                                    }
                                    pos="relative"
                                    borderBottom="1px solid"
                                    borderColor="rgba(65, 119, 119, 0.1)"
                                 >
                                    <Text size="eyebrow" color="text.primary">
                                       Weekly reflection
                                    </Text>
                                    <Icon
                                       name="chevron-down-light"
                                       w={5}
                                       h={5}
                                       color="text.primary"
                                       ml="auto"
                                       transition="transform .2s ease"
                                       transform={
                                          isExpanded
                                             ? 'rotate(180deg)'
                                             : 'rotate(0deg)'
                                       }
                                       pos="absolute"
                                       right={{ base: 3, lg: 4 }}
                                    />
                                 </AccordionButton>
                              </h2>
                              <AccordionPanel
                                 pt={4}
                                 pb={6}
                                 px={6}
                                 bg="background.secondary"
                                 borderBottomLeftRadius="12px"
                                 borderBottomRightRadius="12px"
                                 color="text.primary"
                                 pos="relative"
                              >
                                 <Text mb={4}>
                                    These tailored questionnaires provide
                                    valuable insight into your treatment
                                    progress. Regularly measuring your progress
                                    enables you and your care team to work
                                    together to make personalized adjustment to
                                    your care plan.
                                 </Text>
                                 <NextLinkButton size="sm" href="/weekly-pause">
                                    Begin
                                 </NextLinkButton>

                                 <BottomRightCircles
                                    pos="absolute"
                                    right={0}
                                    bottom={0}
                                    display={{
                                       base: 'none',
                                       lg: 'block',
                                    }}
                                 />
                              </AccordionPanel>
                           </>
                        )}
                     </AccordionItem>
                  </Accordion>
               </Box>
            )}
         </Flex>

         <DailyMoodTrackingModal
            initialMoodTrackingModal={initialMoodTrackingModal}
            w="full"
            onFinishedDailyMoodTracking={handleFinishedDailyMoodTracking}
            date={dailyMoodTrackDate}
         />
      </>
   );
};
