import { WelkinPrograms } from '@innerwell/dtos';
import { usePatientProgram } from '@/contexts/patient-program-context';

export const usePatientInKetamineProgram = (program?: WelkinPrograms) => {
   const { programPhase } = usePatientProgram();

   const programName = program ?? programPhase.program;

   if (!programName) {
      return null;
   }

   return ![
      WelkinPrograms.Therapy,
      WelkinPrograms.GeneralPsychiatry,
      WelkinPrograms.Intake,
   ].includes(programName);
};
