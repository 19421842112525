import { usePatientProgram } from '@/contexts/patient-program-context';
import { IntakeProgramPhases } from '@innerwell/dtos';
import { useMemo } from 'react';

export const usePatientHasFinishedConsent = () => {
   const { programPhase, pathHistory } = usePatientProgram();

   return useMemo(() => {
      if (programPhase && pathHistory.length > 0) {
         return pathHistory.find(
            (path) =>
               path.phase === IntakeProgramPhases.InformedConsentFormFinished,
         )
            ? true
            : false;
      }

      return null;
   }, [pathHistory, programPhase]);
};
