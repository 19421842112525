import { Card } from '@/components/Cards/components/Card';
import { Box, type BoxProps } from '@chakra-ui/react';

type Props = BoxProps;

const ConsentStep = (props: Props) => {
   return (
      <Box {...props}>
         <Card>
            <Card.Image
               src="/images/medical-consult-woman.svg"
               alt="Card image"
            />
            <Card.Title>Consents and permissions</Card.Title>
            <Card.Text>
               Before enrolling in a program at Innerwell, it’s important to
               understand the nature of telehealth treatment. Your Psychiatric
               Clinician will review your consent and onboarding info during
               your first session.
            </Card.Text>
            <Card.ButtonGroup>
               <Card.ButtonLink href="/user-consent/tos">
                  Review and Sign
               </Card.ButtonLink>
            </Card.ButtonGroup>
         </Card>
      </Box>
   );
};

export default ConsentStep;
