import { Divider, Flex, type FlexProps, Text } from '@chakra-ui/react';

type Props = {
   text: string;
} & FlexProps;

export const DividerWithText = ({ text, ...flexProps }: Props) => {
   return (
      <Flex alignItems="center" gap={3} {...flexProps}>
         <Text size="eyebrow" flexShrink={0}>
            {text}
         </Text>
         <Divider
            borderBottom="2px solid"
            borderColor="line.primary"
            opacity={0.2}
         />
      </Flex>
   );
};
