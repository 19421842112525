'use client';

import { Box, type IconProps } from '@chakra-ui/react';
import {
   FormsortFormTypes,
   CustomerIOEvents,
   IntakeProgramPhases,
   WelkinPrograms,
} from '@innerwell/dtos';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import {
   BackgroundGraphicMedicalIntakeMedicalHistory,
   BackgroundGraphicMedicalIntakeMentalHealth,
   BackgroundGraphicMedicalIntakeProfile,
   BackgroundGraphicMedicalIntakePsychedelicExperiences,
   BackgroundGraphicMedicalIntakePsychiatricHistory,
} from '@/components/BackgroundGraphics';
import { ManWithCircle } from '@/components/Images';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import MedicalIntakeLayout from '@/layouts/MedicalIntakeLayout';

export const medicalIntakeFormPaths = [
   'profile',
   'medical-history',
   'psychiatric-history',
   'mental-health-and-wellness',
   'psychedelic-experiences',
] as const;

type FormPath = (typeof medicalIntakeFormPaths)[number];

const formMap = new Map<
   FormPath,
   {
      title: string;
      description: string;
      flowName: FormsortFormTypes;
      image: (props: IconProps) => React.ReactNode;
   }
>([
   [
      'profile',
      {
         title: 'About you',
         description:
            'Your story matters to us. These first questions help us to get to know you better.',
         flowName: FormsortFormTypes.MedicalIntakeProfile,
         image: BackgroundGraphicMedicalIntakeProfile,
      },
   ],
   [
      'medical-history',
      {
         title: 'Physical Health Profile',
         description:
            "A strong mind starts with a strong body! Let's get to know more about your physical health.",
         flowName: FormsortFormTypes.MedicalIntakeMedicalHistory,
         image: BackgroundGraphicMedicalIntakeMedicalHistory,
      },
   ],
   [
      'psychiatric-history',
      {
         title: 'Mental Health Profile',
         description:
            'We look forward to partnering with you on your healing journey. These next questions will help us understand your mental health and wellbeing.',
         flowName: FormsortFormTypes.MedicalIntakePsychiatricHistory,
         image: BackgroundGraphicMedicalIntakePsychiatricHistory,
      },
   ],
   [
      'mental-health-and-wellness',
      {
         title: 'Lifestyle',
         description:
            'Every day should be a mental health day! These next few questions ask a bit about your lifestyle habits to help our experts get to know you better.',
         flowName: FormsortFormTypes.MedicalIntakeMentalHealth,
         image: BackgroundGraphicMedicalIntakeMentalHealth,
      },
   ],
   [
      'psychedelic-experiences',
      {
         title: 'Psychedelic Experience',
         description:
            "Understanding your experience and comfort level with psychedelics helps our experts guide you through this journey with the level of support that's right for you.",
         flowName: FormsortFormTypes.MedicalIntakePsychedelicExperiences,
         image: BackgroundGraphicMedicalIntakePsychedelicExperiences,
      },
   ],
]);

export const MedicalIntakeFormClientPage = () => {
   const pathname = usePathname();
   const formName = pathname.split('/').pop() as FormPath;

   const form = formMap.get(formName);

   const { data: sessionData } = useSession();

   useEffect(() => {
      if (sessionData && formName === 'profile') {
         webApiClient.programs.updateProgram({
            params: {
               progName: WelkinPrograms.Intake,
            },
            body: {
               phaseName: IntakeProgramPhases.MedicalIntakeStarted,
            },
         });

         webApiClient.customerio.track({
            body: {
               id: sessionData['cognito:username'],
               name: CustomerIOEvents.OnboardingStarted,
            },
         });
      }
   }, [formName, sessionData]);

   if (!form) {
      return <Box>Form not found</Box>;
   }

   const { title, description, flowName, image: DesktopImage } = form;

   return (
      <MedicalIntakeLayout
         landingStepTitle={title}
         landingStepText={description}
         landingStepImage={
            <ManWithCircle
               mt={-20}
               position="absolute"
               top={0}
               left="50%"
               display="block"
               transform="translateY(-100%) translateX(-50%)"
               zIndex={3}
            />
         }
         landingStepDesktopImage={<DesktopImage />}
         flowName={flowName}
      />
   );
};
