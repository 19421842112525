import { useQuery } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';

export const useAdsCounter = () => {
   return useQuery({
      queryKey: queryKeys.adsCounter,
      queryFn: async () => {
         const response = await webApiClient.adsCounter.getCounterInfo();
         return response.body;
      },
   });
};
