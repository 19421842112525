 
import { Box, type BoxProps } from '@chakra-ui/react';
import type { ImageProps } from 'next/image';
import NextImg from 'next/image';

export type NextImageProps = Omit<ImageProps, 'layout'> &
   BoxProps & {
      imgWidth?: number;
      imgHeight?: number;
   } & {
      layout?: 'fill' | 'responsive' | 'intrinsic' | 'fixed';
      objectFit?: string;
      objectPosition?: string;
   };

function NextImage(props: NextImageProps) {
   const {
      imgWidth,
      imgHeight,
      width,
      height,
      src,
      layout = 'fill',
      objectFit,
      objectPosition,
      quality = 100,
      rounded,
      alt,
      ...restChakraProps
   } = props;
   return (
      <Box
         w={width}
         h={height}
         position="relative"
         overflow="hidden"
         flexShrink={0}
         rounded={rounded}
         {...restChakraProps}
         __css={{
            img: {
               objectFit: objectFit || 'cover',
               objectPosition: objectPosition || 'center center',
            },
         }}
      >
         <NextImg
            width={
               layout === 'fill'
                  ? undefined
                  : imgWidth || typeof width === 'number'
                    ? width
                    : 50
            }
            height={
               layout === 'fill'
                  ? undefined
                  : imgHeight || typeof height === 'number'
                    ? height
                    : 50
            }
            fill={layout === 'fill'}
            quality={quality}
            src={src}
            alt={alt}
         />
      </Box>
   );
}

export default NextImage;
