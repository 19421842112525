import { useMutation, useQueryClient } from '@tanstack/react-query';

import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';
import { handleSentryException } from '@/utils/sentry';

import { queryKeys } from '@/types/query-keys';

export const useUpdateContactConsent = () => {
   const { toastSuccess, toastError } = useThemedToast();
   const queryClient = useQueryClient();

   return useMutation({
      mutationFn: () => {
         return webApiClient.contactConsent.confirmConsent();
      },

      onSuccess: () => {
         toastSuccess('Consent confirmed successfully.');

         queryClient.invalidateQueries({ queryKey: queryKeys.contactConsent });
      },
      onError: (error) => {
         handleSentryException(error);

         if (typeof error === 'string') {
            toastError(error);
         } else {
            toastError('An unknown error occurred. Please try again.');
         }
      },
   });
};
